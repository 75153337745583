h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
  background: #abf3ba;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #abf3ba, #b5ac49);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #abf3ba, #b5ac49); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.card {
  --width: 8em;
  --height: calc(var(--width) * 1.4);
  width: var(--width);
  height: var(--height);
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5em;
  margin: 2px;
  padding: 0.5em;  
  box-shadow: 0 1px 10px #888888;
}
[card-suit="1"].card-number, [card-suit="2"].card-number {
  font-size: 2em;
  float: left;
  color: red;
  font-family: 'Roboto Slab', serif;
}
[card-suit="0"].card-number, [card-suit="3"].card-number {
  font-size: 2em;
  float: left;
  font-family: 'Roboto Slab', serif;
}
.bottom-suits-box {
  width: 5em;
  height: 10em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
}
.inline {
  display: inline-block;
}
.playingBox{
  width: 100%;
  height: 15em;
}
.playingBoxProfile {
  width: 12em;
  height: 100%;
  text-align: center;
  float: left;
  padding-top: 2em;
}
.playingBoxCards{
  width: calc(100% - 12em);
  height: 100%;
  padding-top: 1.3em;
  padding-bottom: 1.9em;
  padding-left: 1em;
}
.image {
  width: 100px;
  height: 100px;
  
}

.chips{
  padding: 0 10px;
  display: flex; 
  flex-wrap: wrap;
  align-items: center;
}

.chipsImg{
  width: 30px;
  height: 30px;
  margin-right: 6px;
  margin-left: 25px;
}

.chipsAmt{
  font-weight: 500;
  font-size: 18px;
  font-family: 'Roboto Slab', serif;
}

.tableBox{
  height: 5em;
  width: 100%;
  display: flex;
  align-items: center;
}
.tableBox h4{
  width: 120px;
  margin: auto 10px;
  padding: 7px 10px;
  font-size: 20px;
  text-align: right;
  color: #4caf50;
  background-color: white;
  border: 1px solid #4caf50;
  border-radius: 4px;
}
.pointDisplay{
  padding: 5px;
}
.pointDisplay h4{
  width: 80px;
  padding: 7px 10px;
  font-size: 20px;
  text-align: center;
  color: #4caf50;
  background-color: white;
  border: 1px solid #4caf50;
  border-radius: 4px;
}
.button {
  margin: auto 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 .button:hover {
  background-color: #45a049;
}
.disableButton {
  margin: auto 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #dfdfdf;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  cursor: not-allowed;
  transition: background-color 0.3s ease;
}